<template>
  <svg viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6027 15.1884L8.33736 15.1086C6.95635 15.0809 5.5719 15.1363 4.21797 14.8485C2.15833 14.4185 2.01241 12.3102 1.85973 10.5418C1.64935 8.05555 1.7308 5.52419 2.12781 3.05872C2.35194 1.67532 3.23397 0.849844 4.59801 0.760016C9.20262 0.434017 13.8379 0.472652 18.4323 0.624742C18.9175 0.638684 19.4061 0.714889 19.8845 0.80163C22.2463 1.22469 22.3039 3.61386 22.457 5.62509C22.6097 7.65707 22.5452 9.69949 22.2534 11.7176C22.0193 13.3886 21.5713 14.7899 19.6809 14.9252C17.3124 15.102 14.9982 15.2444 12.623 15.1991C12.6231 15.1884 12.6094 15.1884 12.6027 15.1884ZM10.0951 10.9579C11.88 9.91065 13.6308 8.88082 15.4055 7.84056C13.6173 6.79328 11.8698 5.76345 10.0951 4.72319V10.9579Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconYouTube'
}
</script>
